@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900&subset=latin-ext');

body {
	min-height: 100vh;
	font-family: 'Lato', sans-serif;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: url('../img/backgrounds/workstation-336369_1920.jpg') center;
	background-size: cover;
	background-attachment: fixed;
	position: relative;

	.background-cover {
		position: absolute;
		width: 100%;
		min-height: 100vh;
		height: 100%;
		background: rgba(55, 46, 36, 0.8); 
	}

	ul, p, h1, h2, h3, h4, h5, h6 {
		margin: 0;
		padding: 0;
	}

	a {
		text-decoration: none !important;
	}

	.modal-footer {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.btn {
			margin: 5px;
		}
	}

	.btn-default {
		font-size: 12px;
		font-weight: 700;
		text-transform: uppercase;
		color: #fff;
		border: none;
		border-radius: 5px;
		background: @color1;
		padding: 12px 25px;
		transition: all 0.3s;

		&.btn-orange {
			background: #e0621e;
		}

		&.btn-danger {
			background: #dc3545;
		}

		&.btn-success {
			background: #28a745;
		}

		&:hover {
			color: #fff;
			background: @gray;
		}
	}

	.ck-editor-text {
		ul, ol {
			padding-left: 20px;
		}
	}

	.m-t-5 { margin-top: 5px; }
	.m-t-10 { margin-top: 10px; }
	.m-t-15 { margin-top: 15px; }
	.m-t-20 { margin-top: 20px; }
	.m-t-25 { margin-top: 25px; }
	.m-t-30 { margin-top: 30px; }
	.m-b-5 { margin-bottom: 5px; }
	.m-b-10 { margin-bottom: 10px; }
	.m-b-15 { margin-bottom: 15px; }
	.m-b-20 { margin-bottom: 20px; }
	.m-b-25 { margin-bottom: 25px; }
	.m-b-30 { margin-bottom: 30px; }
	.p-t-5 { padding-top: 5px; }
	.p-t-10 { padding-top: 10px; }
	.p-t-15 { padding-top: 15px; }
	.p-t-20 { padding-top: 20px; }
	.p-t-25 { padding-top: 25px; }
	.p-t-30 { padding-top: 30px; }
	.p-b-5 { padding-bottom: 5px; }
	.p-b-10 { padding-bottom: 10px; }
	.p-b-15 { padding-bottom: 15px; }
	.p-b-20 { padding-bottom: 20px; }
	.p-b-25 { padding-bottom: 25px; }
	.p-b-30 { padding-bottom: 30px; }
}

.system-alerts {
	position: fixed;
	top: 30px;
	width: 100%;
	z-index: 999;
	text-align: center;

	.alert {
		background: rgba(255, 255, 255, 0.75);
		border-radius: 5px;
		border: none;
		transition: all 0.3s;
		box-shadow: 3px 2px 15px 0px rgba(0, 0, 0, 0.13);

		p {
			color: @color1;
		}

		button {
			color: @color1;
			opacity: 1;
			outline: 0;

			span {
				font-weight: 300;
				font-size: 30px;
				line-height: 20px;
			}
		}

		&:hover {
			background: rgba(255, 255, 255, 0.95);
		}
	}
}

.breadcrumb-flexbox {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;

	.backlink {
		color: #fff;
		font-size: 22px;
		font-weight: 900;
	}
}

.breadcrumb {
	margin-top: 20px;
	position: relative;
	padding: 0;
	background: none;

	li {
		font-size: 11px;
		text-transform: uppercase;

		a {
			color: #fff;
		}

		&.active {
			color: #999;
		}
	}
}

div.section-header {
	.navbar {
		padding: 15px 0;
		min-height: auto;
		margin-bottom: 0;
		background: #24619a;
		background: @color1;
		border: none;
		border-radius: 0;

		.container {
			display: flex;
			justify-content: space-between;
			align-items: center;

			&:before, &:after {
				content: none;
			}
		}

		.navbar-brand {
			padding: 0 0 0 15px;
			height: auto;

			img {
				height: 35px;
			}
		}

		.navbar-toggle {
			color: #fff !important;
		}

		.navbar-collapse {
			padding-left: 0;
			padding-right: 0;
			margin-right: 0;
			margin-left: 0;

			.navbar-nav {
				margin-left: 0;
				margin-right: 0;

				li {
					margin-right: 20px;

					a {
						padding: 0;
						text-transform: uppercase;
						color: #fff;
						font-size: 13px;
						transition: all 0.3s;
						opacity: 0.9;
					}

					&.logout {
						a {
							font-weight: 900;
						}
					}

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
	}
}

div.logowanie-section-content {
	.content-flexbox {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;

		.flexbox-card {
			background: rgba(255, 255, 255, 0.9);
			border-radius: 10px;
			padding: 60px;
			width: 475px;
			max-width: 100%;
			text-align: center;

			.heading {
				font-size: 12px;
				line-height: 12px;
				font-weight: 700;
				text-transform: uppercase;
				color: #414141;
			}

			.text {
				margin-top: 10px;
				font-size: 17px;
				font-weight: 300;
				color: #414141;
			}

			label {
				margin-top: 30px;
				font-size: 12px;
				font-weight: 700;
				text-transform: uppercase;
				color: #414141;
			}

			label.checkbox-wrapper {
				cursor: pointer;

				.radio-box {
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 17px;
					text-transform: none;
					font-weight: 700;
					text-align: left;

					&:before {
						content: '';
						min-width: 14px;
						max-width: 14px;
						height: 14px;
						display: block;
						background: url('../img/pictures/checkbox-icon.png') no-repeat;
						margin-right: 10px;
						transition: all 0.3s;
					}
				}

				input {
					visibility: hidden;
					position: absolute;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}

			label > input:checked + .radio-box {
				&:before {
					background: url('../img/pictures/checkbox-checked-icon.png') no-repeat;
				}
			}

			input {
				max-width: 400px;
				width: 100%;
				border-radius: 5px;
				border: none;
				height: 40px;
				padding: 15px;
				display: block;
				outline: none;
			}

			.btn {
				margin-top: 30px;
				width: 100%;
			}
		}
	}
}

div.moje-konto-section-content {
	padding: 50px 0;
	position: relative;

	.content-card {
		border-radius: 10px;
		background: rgba(255, 255, 255, 0.9);
		padding: 30px;

		.heading {
			font-size: 12px;
			line-height: 12px;
			font-weight: 700;
			text-transform: uppercase;
			color: #414141;
			text-align: center;
		}

		.text {
			margin-top: 10px;
			font-size: 17px;
			font-weight: 300;
			color: #414141;
			text-align: center;
		}

		.card-form {
			width: 800px;
			max-width: 100%;
			margin: 0 auto;
			text-align: center;

			label {
				margin-top: 30px;
				font-size: 12px;
				font-weight: 700;
				text-transform: uppercase;
				color: #414141;
			}

			label.checkbox-wrapper {
				cursor: pointer;
				display: block;

				.radio-box {
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 14px;
					text-transform: none;
					font-weight: 500;
					text-align: left;

					&:before {
						content: '';
						min-width: 14px;
						max-width: 14px;
						height: 14px;
						display: block;
						background: url('../img/pictures/checkbox-icon.png') no-repeat;
						margin-right: 10px;
						transition: all 0.3s;
					}
				}

				input {
					visibility: hidden;
					position: absolute;
					width: auto;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}

			label > input:checked + .radio-box {
				&:before {
					background: url('../img/pictures/checkbox-checked-icon.png') no-repeat;
				}
			}

			input {
				width: 100%;
				border-radius: 5px;
				border: none;
				height: 40px;
				padding: 15px;
				display: block;
				outline: none;
				text-align: center;
			}

			.btn {
				margin-top: 30px;
				width: 100%;
			}
		}
	}
}

div.dashboard-section-content {
	padding: 70px 0 50px 0;
	position: relative;

	.content-flexbox {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.flexbox-service {
			margin-top: 30px;
			width: 23%;
			text-align: center;
			background: rgba(255, 255, 255, 0.9);
			border-radius: 10px;
			overflow: hidden;

			.service-image {
				img {
					width: 100%;
				}
			}

			.name {
				padding: 15px;
				text-transform: uppercase;
				color: #414141;
				font-weight: 700;
			}
		}
	}
}

div.szkolenia-section-content {
	padding: 70px 0 50px 0;
	position: relative;

	.content-flexbox {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.flexbox-service {
			margin-top: 30px;
			width: 23%;
			text-align: center;
			background: rgba(100, 100, 100, 0.9);
			border-radius: 10px;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			&.during {
				background: rgba(140, 134, 68, 0.9);
			}

			&.failed {
				background: rgba(214, 42, 42, 0.7);
			}

			&.done {
				background: rgba(102, 140, 68, 0.9);
			}

			.name {
				color: #fff;
				background: @color1;
				font-size: 22px;
				font-weight: 700;
				padding: 20px 5px;
			}

			.status {
				padding: 15px;
				text-transform: uppercase;
				color: #fff;
				font-weight: 300;

				strong {
					font-weight: 900;
				}
			}

			.btn {
				border-radius: 0 0 5px 5px;
			}
		}
	}
}

div.szkolenie-section-content {
	padding: 50px 0;
	position: relative;

	.content-card {
		border-radius: 10px;
		background: rgba(255, 255, 255, 0.9);
		padding: 30px;
		text-align: center;

		.heading {
			font-size: 32px;
			line-height: 32px;
			font-weight: 700;
			color: #414141;
		}

		.text {
			margin-top: 10px;
			font-size: 17px;
			font-weight: 300;
			color: #414141;
		}

		.info {
			margin-top: 10px;
			font-size: 17px;
			font-weight: 700;
			color: #d62a2a
		}

		.terms {
			p {
				margin-top: 10px;
				font-size: 17px;
				font-weight: 300;
				color: #414141;
			}
		}

		.data {
			font-size: 23px;
			line-height: 28px;
			color: #414141;
		}

		.btn {
			margin-top: 30px;
			width: 100%;
		}
	}
}

div.szkolenie-slajd-section-content {
	padding: 20px 0 50px 0;
	position: relative;

	.content-flexbox {
		display: flex;
		justify-content: space-between;

		.flexbox-image {
			background: rgba(255, 255, 255, 0.9);
			border-radius: 10px;
			padding: 20px;

			img {
				border-radius: 10px;
				width: 100%;
			}
		}

		.flexbox-sidebar {
			min-width: 300px;
			max-width: 300px;
			margin-left: 50px;
			background: rgba(255, 255, 255, 0.9);
			border-radius: 10px;
			padding: 20px;

			.heading {
				font-size: 20px;
				margin-bottom: 20px;

				strong {
					font-size: 16px;
					font-weight: 900;
				}
			}

			select {
				margin-top: 20px;
				border-radius: 5px;
				border: 1px solid @color1;
				height: 45px;
				width: 100%;
				padding: 10px;
				outline: 0;
			}

			.btn-100 {
				width: 100%;
				margin-top: 20px;
			}

			.modal-body {
				ul {
					li {
						margin-bottom: 12px;

						a {
							font-size: 12px;
							text-transform: uppercase;
							font-weight: 900;
							display: flex;
							align-items: center;

							i {
								font-size: 16px;
								margin-right: 7px;
							}
						}

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}

div.test-slajd-section-content {
	padding: 20px 0 50px 0;
	position: relative;

	.content-flexbox {
		display: flex;
		justify-content: space-between;

		.flexbox-body {
			background: rgba(255, 255, 255, 0.9);
			border-radius: 10px;
			padding: 20px;
			width: 72%;

			.heading {
				font-size: 24px;
				font-weight: 700;
			}

			.question {
				margin-top: 20px;

				p {
					font-size: 18px;

				}

				img {
					border-radius: 10px;
					max-width: 100%;
				}
			}

			.answers {
				label.checkbox-wrapper {
					cursor: pointer;
					display: block;

					.radio-box {
						display: flex;
						align-items: center;
						font-size: 22px;
						text-transform: none;
						font-weight: 700;
						text-align: left;

						&:before {
							content: '';
							min-width: 14px;
							max-width: 14px;
							height: 14px;
							display: block;
							background: url('../img/pictures/checkbox-icon.png') no-repeat;
							margin-right: 10px;
							transition: all 0.3s;
						}
					}

					input {
						visibility: hidden;
						position: absolute;
					}

					&.wrong {
						background: rgba(214, 42, 42, 0.3);
						border-radius: 5px;
					}

					&.correct {
						background: rgba(139, 214, 42, 0.3);
						border-radius: 5px;
					}

					&:first-child {
						margin-top: 40px;
					}
				}

				label > input:checked + .radio-box {
					&:before {
						background: url('../img/pictures/checkbox-checked-icon.png') no-repeat;
					}
				}
			}

			.buttons-wrapper {
				margin-top: 20px;				
			}
		}

		.flexbox-sidebar {
			width: 25%;
			margin-left: 50px;
			background: rgba(255, 255, 255, 0.9);
			border-radius: 10px;
			padding: 20px;

			.heading {
				font-size: 20px;
				margin-bottom: 20px;

				strong {
					font-size: 16px;
					font-weight: 900;
				}
			}

			.score {
				border-radius: 5px;
				text-align: center;
				padding: 10px;

				p {
					font-size: 18px;
					color: #fff;
					text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
				}

				&.failed {
					background: rgba(214, 42, 42, 0.7);
				}

				&.done {
					background: rgba(139, 214, 42, 0.8);
				}
			}

			.btn-100 {
				width: 100%;
				margin-top: 20px;
			}

			.info {
				margin-top: 20px;
				padding: 10px;
				border: 1px solid darken(@gray-lighter, 15%);
				border-radius: 5px;
			}
		}
	}
}

div.materialy-szkoleniowe-section-content {
	padding: 50px 0;
	position: relative;

	.content-card {
		border-radius: 10px;
		background: rgba(255, 255, 255, 0.9);
		padding: 30px;

		.heading {
			font-size: 12px;
			line-height: 12px;
			font-weight: 700;
			text-transform: uppercase;
			color: #414141;
			text-align: center;
		}

		.text {
			margin-top: 10px;
			font-size: 17px;
			font-weight: 300;
			color: #414141;
			text-align: center;
		}

		ul {
			margin-top: 30px;

			li {
				margin-bottom: 12px;
				display: flex;
				align-items: center;

				a {
					font-size: 12px;
					text-transform: uppercase;
					font-weight: 900;
					display: flex;
					align-items: center;

					i {
						font-size: 16px;
						margin-right: 7px;
					}
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}

div.uzytkownicy-section-content {
	padding: 70px 0 50px 0;
	position: relative;
	
	.inputs-wrapper {
		display: flex;
		flex-wrap: wrap;

		input {
			width: 180px;
			max-width: 100%;
			border-radius: 5px;
			border: 1px solid @color1;
			height: 40px;
			padding: 15px;
			display: block;
			outline: none;
			margin-right: 10px;
			margin-bottom: 5px;

			&:last-child {
				margin-right: auto;
			}
		}
	}

	.content-flexbox {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.flexbox-service {
			margin-top: 30px;
			width: 23%;
			text-align: center;
			border-radius: 10px;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			background: @color1;

			.name {
				color: #fff;
				font-size: 22px;
				font-weight: 700;
				padding: 20px 5px;
			}

			.delete {
				background: rgba(100, 100, 100, 0.9);
				padding: 15px 0;
				font-size: 20px;
				text-transform: uppercase;
				font-weight: 700;
				color: #fff;
				display: block;
			}

			.edit {
				background: rgba(139, 214, 42, 0.8);
				padding: 15px 0;
				font-size: 20px;
				text-transform: uppercase;
				font-weight: 700;
				color: #fff;
				display: block;
			}

			&.service-add {
				justify-content: center;
				background: rgba(139, 214, 42, 0.8);
				color: #fff;
				font-weight: 700;
				text-transform: uppercase;

				strong {
					font-size: 50px;
				}
			}
		}
	}
}

div.dodaj-szkolenie-section-content {
	padding: 50px 0;
	position: relative;

	.content-card {
		border-radius: 10px;
		background: rgba(255, 255, 255, 0.9);
		padding: 30px;

		.heading {
			font-size: 12px;
			line-height: 12px;
			font-weight: 700;
			text-transform: uppercase;
			color: #414141;
			text-align: center;
		}

		.text {
			margin-top: 10px;
			font-size: 17px;
			font-weight: 300;
			color: #414141;
			text-align: center;
		}

		label {
			margin-top: 30px;
			font-size: 12px;
			font-weight: 700;
			text-transform: uppercase;
			color: #414141;
		}

		input {
			width: 100%;
			border-radius: 5px;
			border: none;
			height: 40px;
			padding: 15px;
			display: block;
			outline: none;
		}

		textarea {
			width: 100%;
			border-radius: 5px;
			border: none;
			height: 120px;
			padding: 15px;
			display: block;
			outline: none;
		}

		.btn {
			margin-top: 30px;
		}

		.card-slide {
			margin-top: 30px;
			padding: 0 20px 20px 20px;
			border: 1px solid @color1;
			border-radius: 5px;

			.slide-heading {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;

				.heading-service {
					width: 35%;
				}

				.btn {
					width: 22%;
				}
			}
		}
	}
}

div.dodaj-test-section-content {
	padding: 50px 0;
	position: relative;

	.content-card {
		border-radius: 10px;
		background: rgba(255, 255, 255, 0.9);
		padding: 30px;

		.heading {
			font-size: 12px;
			line-height: 12px;
			font-weight: 700;
			text-transform: uppercase;
			color: #414141;
			text-align: center;
		}

		.text {
			margin-top: 10px;
			font-size: 17px;
			font-weight: 300;
			color: #414141;
			text-align: center;
		}

		label {
			margin-top: 30px;
			font-size: 12px;
			font-weight: 700;
			text-transform: uppercase;
			color: #414141;
		}

		input {
			width: 100%;
			border-radius: 5px;
			border: none;
			height: 40px;
			padding: 15px;
			display: block;
			outline: none;

			&.input-red {
				border: 1px solid rgba(214, 42, 42, 0.7);
			}
		}

		textarea {
			width: 100%;
			border-radius: 5px;
			border: none;
			height: 120px;
			padding: 15px;
			display: block;
			outline: none;
		}

		select {
			margin-top: 30px;
			border-radius: 5px;
			border: 1px solid @color1;
			height: 45px;
			width: 100%;
			padding: 10px;
			outline: 0;
		}

		.btn {
			margin-top: 30px;
		}

		.card-flexbox {
			display: flex;
			justify-content: space-between;

			.flexbox-body {
				width: 70%;
			}

			.flexbox-sidebar {
				width: 27%;
			}
		}

		.card-slide {
			margin-top: 30px;
			padding: 20px;
			border: 1px solid @color1;
			border-radius: 5px;

			label {
				margin-top: 0;
			}
			
			.slide-bottom {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;

				.bottom-answers {
					width: 60%;

					.answer-service {
						margin-top: 20px;
						display: flex;
						justify-content: space-between;
						align-items: flex-end;

						.service-input {
							min-width: 350px;
							max-width: 350px;
						}

						.service-box {
							label.checkbox-wrapper {
								cursor: pointer;
								margin-right: 15px;
								margin-bottom: 0;

								.radio-box {
									display: flex;
									align-items: center;
									justify-content: center;
									flex-direction: column;
									font-size: 10px;
									text-transform: uppercase;
									font-weight: 700;

									&:before {
										content: '';
										min-width: 14px;
										max-width: 14px;
										height: 14px;
										display: block;
										background: url('../img/pictures/checkbox-icon.png') no-repeat;
										margin-bottom: 5px;
										transition: all 0.3s;
									}
								}

								input {
									visibility: hidden;
									position: absolute;
									width: auto;
									height: auto;
								}

								&:last-child {
									margin-right: 0;
								}
							}

							label > input:checked + .radio-box {
								&:before {
									background: url('../img/pictures/checkbox-checked-icon.png') no-repeat;
								}
							}	
						}
						
						.btn {
							margin-top: 0;
							width: auto;
							padding: 6px 10px;
						}
					}
				}

				.bottom-service {
					margin-top: 20px;
					width: 20%;
				}

				.btn-delete {
					margin-top: 43px;
					width: 15%;
				}
			}
		}
	}
}

div.harmonogram-section-content {
	padding: 50px 0;
	position: relative;

	.content-card {
		border-radius: 10px;
		background: rgba(255, 255, 255, 0.9);
		padding: 30px;

		.heading {
			font-size: 12px;
			line-height: 12px;
			font-weight: 700;
			text-transform: uppercase;
			color: #414141;
			text-align: center;
		}

		.text {
			margin-top: 10px;
			font-size: 17px;
			font-weight: 300;
			color: #414141;
			text-align: center;
			margin-bottom: 30px;
		}

		.card-flexbox {
			margin-top: 30px;
			display: flex;
			justify-content: space-between;
			margin-bottom: 1px;

			&:nth-child(even) {
				.flexbox-service {
					background: @gray-lighter;
				}
			}

			&:last-child {
				margin-bottom: 0;
			}

			.flexbox-service {
				.service-heading {
					white-space: nowrap;
					font-weight: 700;
					font-size: 11px;
					padding: 1px 3px;
					background: @color1;
					color: #fff;
				}

				.service-text {
					height: 35px;
					margin-bottom: 1px;
					display: flex;
					align-items: center;
					font-weight: 700;
					font-size: 11px;
					padding: 0 3px;

					&.edit {
						color: @color1;
						text-transform: uppercase;
					}

					&.delete {
						color: rgba(214, 42, 42, 1);
						text-transform: uppercase;
					}

					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			&.card-first {
				.service-1 {
					width: 4%;
					text-align: center;

					.service-text {
						justify-content: center;
					}
				}

				.service-2 {
					width: 41%;
				}

				.service-3 {
					width: 20%;
				}

				.service-4 {
					width: 5%;
				}

				.service-5 {
					width: 10%;
				}

				.service-6 {
					width: 10%;
				}

				.service-7 {
					width: 5%;
					text-align: center;

					.service-text {
						justify-content: center;
					}
				}

				.service-8 {
					width: 5%;
					text-align: center;

					.service-text {
						justify-content: center;
					}
				}
			}

			&.card-second {
				.service-1 {
					width: 4%;
					text-align: center;

					.service-text {
						justify-content: center;
					}
				}

				.service-2 {
					width: 66%;
				}

				.service-5 {
					width: 10%;
				}

				.service-6 {
					width: 10%;
				}

				.service-7 {
					width: 5%;
					text-align: center;

					.service-text {
						justify-content: center;
					}
				}

				.service-8 {
					width: 5%;
					text-align: center;

					.service-text {
						justify-content: center;
					}
				}
			}
		}
	}
}

div.nowe-przypisanie-section-content {
	padding: 50px 0;
	position: relative;

	.content-card {
		border-radius: 10px;
		background: rgba(255, 255, 255, 0.9);
		padding: 30px;

		.heading {
			font-size: 12px;
			line-height: 12px;
			font-weight: 700;
			text-transform: uppercase;
			color: #414141;
			text-align: center;
		}

		.text {
			margin-top: 10px;
			font-size: 17px;
			font-weight: 300;
			color: #414141;
			text-align: center;
			margin-bottom: 30px;
		}

		.card-select {
			display: flex;
			justify-content: space-between;

			select {
				margin-top: 20px;
				border-radius: 5px;
				border: 1px solid @color1;
				height: 45px;
				width: 22%;
				padding: 10px;
				outline: 0;
			}
		}

		label {
			margin-top: 30px;
			font-size: 12px;
			font-weight: 700;
			text-transform: uppercase;
			color: #414141;
		}

		input {
			width: 100%;
			border-radius: 5px;
			border: none;
			height: 40px;
			padding: 15px;
			display: block;
			outline: none;
		}

		.card-input {
			display: flex;
			justify-content: space-between;

			.input-service {
				width: 48%;
			}
		}

		.buttons-wrapper {
			margin-top: 30px;
		}
	}
}

div.administrator-materialy-szkoleniowe-section-content {
	padding: 50px 0;
	position: relative;

	.content-card {
		border-radius: 10px;
		background: rgba(255, 255, 255, 0.9);
		padding: 30px;

		.heading {
			font-size: 12px;
			line-height: 12px;
			font-weight: 700;
			text-transform: uppercase;
			color: #414141;
			text-align: center;
		}

		.text {
			margin-top: 10px;
			font-size: 17px;
			font-weight: 300;
			color: #414141;
			text-align: center;
			margin-bottom: 30px;
		}

		.cards-wrapper {
			margin-top: 30px;

			.card-flexbox {
				display: flex;
				justify-content: space-between;
				margin-bottom: 1px;

				&:first-child {
					.flexbox-service {
						.service-heading {
							display: block;
						}
					}
				}

				.flexbox-service {
					.service-heading {
						white-space: nowrap;
						font-weight: 700;
						font-size: 11px;
						padding: 1px 3px;
						background: @color1;
						color: #fff;
						display: none;
					}

					.service-text {
						height: 35px;
						margin-bottom: 1px;
						display: flex;
						align-items: center;
						font-weight: 700;
						font-size: 11px;
						padding: 0 3px;

						&.edit {
							color: @color1;
							text-transform: uppercase;
						}

						&.delete {
							color: rgba(214, 42, 42, 1);
							text-transform: uppercase;
						}

						&:last-child {
							margin-bottom: 0;
						}
					}

					select {
						margin: 5px 0;
						border-radius: 5px;
						border: 1px solid @color1;
						height: 25px;
						width: 100%;
						padding: 5px;
						outline: 0;
						font-size: 12px;
					}
				}

				.service-1 {
					width: 4%;
					text-align: center;

					.service-text {
						justify-content: center;
					}
				}

				.service-2 {
					width: 51%;
				}

				.service-3 {
					width: 40%;
				}

				.service-4 {
					width: 5%;
					text-align: center;

					.service-text {
						justify-content: center;
					}
				}
			}
		}

		.modal-body {
			select {
				margin-top: 20px;
				border-radius: 5px;
				border: 1px solid @color1;
				height: 45px;
				width: 100%;
				padding: 10px;
				outline: 0;
			}
		}
	}
}