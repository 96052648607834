@media (max-width: @screen-md-max) {

}

@media (max-width: @screen-sm-max) {
	
}

@media (max-width: @screen-xs-max) {

}

@media (max-width: 500px) {
	div.section-header {
		.navbar {
			.container {
				display: block;
				width: 100%;

				.navbar-header {
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-direction: row-reverse;
					margin-right: 0;
					margin-left: 0;

					&:before, &:after {
						content: none;
					}
				}
			}

			.navbar-brand {
				padding: 0;

				img {
					height: 28px;
				}
			}

			.navbar-toggle {
				margin-right: 0;
			}
		}
	}

	div.nowe-przypisanie-section-content {
		.content-card {
			.card-select {
				flex-direction: column;

				select {
					width: 100%;
				}
			}
		}
	}
}